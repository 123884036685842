<template>
  <div class="traceTheSource-reporting">
    <div class="traceTheSource-reporting-header">
      <div class="traceTheSource-reporting-header-left">
        <i class="back" @click="goBack"></i>
        <div  @click="goBack" style="font-size: 12px;font-weight: 500;color: #4587ff;margin-left: -10px;cursor:pointer;">文本存证</div>
        <!-- <div class="progress">
          <el-progress :text-inside="true" :stroke-width="15" color="#4587FF" :percentage="100">
        </el-progress></div> -->
      </div>
      <div class="traceTheSource-reporting-header-title">历史存证</div>
      <div class="traceTheSource-reporting-header-refresh" @click="refresh">
        <img :src="require('assets/images/refresh-gray.png')" alt="" />
      </div>
      <!-- <div class="traceTheSource-reporting-header-right">
        总扣减字符：<span class="char-num">123123</span>
      </div> -->
    </div>
    <div class="traceTheSource-reporting-reportform">
      <div class="traceTheSource-reporting-reportform-search">
        <div class="query-filename">
          <el-input
            placeholder="请输入文件名搜索"
            suffix-icon="el-icon-search"
            v-model="queryFileName"
            @change="searchFileName"
          >
          </el-input>
        </div>
        <div class="query-date">
          <el-date-picker
            v-model="queryDate"
            type="date"
            placeholder="请选择日期搜索"
            value-format="yyyy-MM-dd"
            @change="searchDate"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="traceTheSource-reporting-reportform-table">
        <el-table
          :data="reportData"
          style="width: 1185px; margin: 0 auto"
        >
          <el-table-column prop="" label="序号" width="90">
            <template slot-scope="{ row: { status }, $index: index }">
                <div :class="['states', status === 1 ? 'orange' : status === 0 ? 'blue' : status === 2 ? 'green' : 'gray']">
                  {{ status === 1 ? '未存证' : status === 0 ? '存证中' : status === 2 ? '完成' : '失败' }}
                </div>
                <div>{{ index + 1 }}</div>
              </template>
          </el-table-column>
          <el-table-column prop="filename" label="文件名"></el-table-column>
          <el-table-column width="45"></el-table-column>
          <el-table-column label="数据类型" width="120">
            <template slot-scope="{ row: { suffix } }">{{ suffix }}</template>
          </el-table-column>
          <el-table-column
            prop="fs"
            label="数据大小"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="价格"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column width="40"></el-table-column>
          <el-table-column label="存证时间" width="180">
            <template slot-scope="{ row: { createTime } }">
              {{ createTime.split("T")[0] + " " + createTime.split("T")[1] }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template
              slot-scope="{
                row: { id, filename, url, provePath, officialProve },
                $index: i,status
              }"
            >
              <div class="operate">
                <span class="zip" v-if="completeStatus(status)" @click="downloadZip(id, filename)"></span>
                <span class="report" @click.stop="selectMenu(i)">
                  <div
                    :class="['submenu', subIndex === i ? 'submenu-active' : '']"
                    @click="selectMenuItem"
                  >
                    <span :data-subitem="url">源文件</span>
                    <span :data-subitem="provePath">官方证书</span>
                    <span :data-subitem="officialProve">可信官方证书</span>
                  </div>
                </span>
                <span class="delete" @click="delHandler(id)"><i></i></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="traceTheSource-reporting-footer">
      <el-pagination
        background
        layout="prev,pager,next"
        prev-text="上一页"
        next-text="下一页"
        hide-on-single-page
        :pager-count="5"
        :page-size="pageSize"
        :page-count="pageCount"
        :current-page="pageNum"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="showTips"
      :modal="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="340px"
    >
      <div class="content">返回到编辑区，当前未存证完成文件将被撤销</div>
      <template #footer>
        <div class="footer">
          <span @click="showTips = false">确定返回</span>
          <span @click="showTips = false">取消</span>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showTips2"
      :modal="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="340px"
    >
      <div class="content">{{ tips_text }}</div>
      <template #footer>
        <div class="footer">
          <span @click="showTips2 = false">等一等</span>
          <span @click="showTips2 = false">取消上传</span>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { findStoreHistoryList, delExistEvidence } from "api/store";
import { previewUrl } from "api/common";
export default {
  name: "StoreRightList",
  data() {
    return {
      //报表数据
      reportData: [],
      //原创等级
      originalLang: [
        {
          level: 1,
          icon: require("assets/images/original-a-plus.png")
        },
        {
          level: 2,
          icon: require("assets/images/original-a.png")
        },
        {
          level: 3,
          icon: require("assets/images/original-b-plus.png")
        },
        {
          level: 4,
          icon: require("assets/images/original-b.png")
        },
        {
          level: 5,
          icon: require("assets/images/original-c.png")
        }
      ],
      //当前页
      pageNum: 1,
      //一页大小
      pageSize: 11,
      //总页数
      pageCount: 1,
      //弹框显示
      showTips: false,
      showTips2: false,
      //弹框提示内容
      tips_text: "",
      subIndex: -1,
      //文件名搜索
      queryFileName: "",
      //时间搜索
      queryDate: "",
      //当前搜索条件
      searchKey: ""
    };
  },
  methods: {
    //完成状态
    completeStatus(s){
      return s == 2;
    },
    goBack() {
      this.$router.push({
        path: "/store/storeRightBox"
      });
    },
    //菜单链接预览
    async locationSubItem(source) {
      const params = {
        url: this.$store.state.onlineBasePath + source
      };
      this.$store.commit("playLifeLoading", true);
      const res = await previewUrl(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        window.open(res.data);
      }
    },
    //文件名搜索
    async searchFileName() {
      this.pageNum = 1;
      this.searchKey = "filename";
      await this.queryReportByCondition(this.searchKey, this.queryFileName);
    },
    //时间搜索
    async searchDate() {
      this.pageNum = 1;
      this.searchKey = "date";
      this.queryReportByCondition(this.searchKey, this.queryDate);
    },
    //搜索条件查询报表
    async queryReportByCondition(key, data) {
      const params = {
        page: this.pageNum,
        limit: 10,
        idNumber: this.$store.state.realNameData.idNumber,
        realnameId: this.$store.state.realNameData.id,
        [key]: data
      };
      this.$store.commit("playLifeLoading", true);
      const res = await findStoreHistoryList(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        this.reportData = res.data.data;
        this.pageCount = Math.ceil(res.data.total / this.pageSize);
      } else {
        this.reportData = [];
        this.pageCount = 1;
      }
    },
    delHandler(id) {
      this.$confirm("是否删除该文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        await this.delReport(id);
        this.findReportData();
      });
    },
    //删除报表
    async delReport(id) {
      const params = { id };
      this.$store.commit("playLifeLoading", true);
      const res = await delExistEvidence(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功"
        });
      } else {
        this.$message({
          type: "error",
          message: res.message
        });
      }
    },
    //刷新报表
    async refresh() {
      this.$store.commit("playLifeLoading", true);
      await this.findReportData();
      this.$store.commit("playLifeLoading", false);
    },
    currentChange(cur) {
      let searchValue = this.queryFileName || this.queryDate;
      this.pageNum = cur;
      if (searchValue) {
        this.queryReportByCondition(this.searchKey, searchValue);
      } else {
        this.findReportData();
      }
    },
    getOriginalIcon(l) {
      return this.originalLang.filter((i) => i.level === l)[0].icon;
    },
    //下载压缩包
    downloadZip(id, fileName) {
      this.$bus.$emit("zipPackDownload", id, fileName);
    },
    //查询报表数据
    async findReportData() {
      const params = {
        page: this.pageNum,
        idNumber: this.$store.state.realNameData.idNumber,
        realnameId: this.$store.state.realNameData.id
      };
      this.$store.commit("playLifeLoading", true);
      const res = await findStoreHistoryList(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.reportData = res.data.data;
        this.pageCount = Math.ceil(res.data.total / this.pageSize);
      } else {
        this.reportData = [];
        this.pageCount = 1;
      }
    },
    //报表操作菜单
    selectMenu(i) {
      if (this.subIndex === i) {
        return (this.subIndex = -1);
      }
      this.subIndex = i;
    },
    //选择菜单项
    selectMenuItem(e) {
      const target = e.target;
      if (target.dataset.subitem && target.dataset.subitem !== "") {
        this.locationSubItem(target.dataset.subitem);
      }else{
        this.$message({
          type: "success",
          message: "暂无记录"
        });
      } 
    },
    clickBlankHandler() {
      this.subIndex = -1;
    }
  },
  created() {
    this.findReportData();
  },
  mounted() {
    document.addEventListener("click", this.clickBlankHandler);
  },
  destroyed() {
    document.removeEventListener("click", this.clickBlankHandler);
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  top: 50%;
  margin-top: -186px !important;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 12px 12px 0px;
    .el-dialog__title {
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-text-gray;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 12px;
    }
  }
  .el-dialog__body {
    padding: 40px 25px;
    .content {
      @include flex-center;
      font-size: $font-size-medium-x;
      font-weight: 500;
      color: $color-text-black;
      line-height: 26px;
    }
  }
  .el-dialog__footer {
    .footer {
      @include flex-between;
      span {
        font-size: $font-size-medium;
        font-weight: 500;
        color: $color-text-active;
        cursor: pointer;
      }
    }
  }
}

.traceTheSource-reporting {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  height: 100%;
  flex: 1;
  position: relative;
  &-header {
    @include flex-between;
    height: 45px;
    border-bottom: 1px solid #eef0f4;
    &-left {
      @include flex-start;
      margin-left: 20px;
      .back {
        position: relative;
        width: 20px;
        height: 18px;
        margin-right: 15px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: url("~assets/images/back-to-editor.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .progress {
        width: 187px;
      }
    }
    &-title {
      font-size: 12px;
      font-weight: 500;
      color: #4587ff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &-refresh {
      @include flex-start;
      margin-right: 21px;
      img {
        @include noselect;
        cursor: pointer;
      }
    }
  }
  &-reportform {
    padding: 0 59px 0 54px;
    // flex: 1;
    &-search {
      @include flex-start;
      margin-top: 20px;
      > div {
        ::v-deep .el-input {
          input {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .query-date {
        margin-left: 20px;
      }
    }
    &-table {
      margin-top: 21px;
      ::v-deep .el-table::before {
        background-color: transparent;
      }
      ::v-deep .el-table {
        overflow: visible;
        .el-table__body-wrapper {
          overflow: visible;
        }
      }
      ::v-deep .el-table td.el-table__cell {
        overflow: hidden;
          &:first-child {
            @include noselect;
            .cell {
              overflow: visible;
            }
          }
          &:last-child {
            overflow: visible;
            .cell {
              overflow: visible;
            }
          }
        border-bottom: none;
        .cell {
          @include ellipsis;
          position: relative;
          .states {
            position:absolute;
            left:30px;
            top:50%;
            width:50px;
            height:18px;
            margin-top:-9px;
            font-size:12px;
            font-weight: 800;
            text-align: center;
            line-height:18px;
            color:#fff;
            z-index:1;
            &::before{
              content:"";
              position:absolute;
              left:0;
              top:0;
              display:block;
              width:100%;
              height:100%;
              z-index:-1;
            }
          }
          .gray{
            &::before{
              background:url("~assets/images/rhombus-gray.png") no-repeat;
            }
            color: #999;
          }
          .green {
            &::before{
              background:url("~assets/images/rhombus-green.png") no-repeat;
            }
          }
          .orange {
            &::before{
              background:url("~assets/images/rhombus-orange.png") no-repeat;
            }
          }
          .blue{
            &::before{
              background:url("~assets/images/rhombus-blue.png") no-repeat;
            }
          }
          .revoke,
          .status {
            @include noselect;
            cursor: pointer;
          }
          .evaluate-wrap {
            @include noselect;
            .original,
            .homology {
              @include flex-start;
              .txt {
                margin-left: 5px;
                font-size: 12px;
                font-weight: 500;
                color: #4587ff;
              }
            }
            .homology {
              .txt {
                font-size: 12px;
                font-weight: 500;
                &.is-homology {
                  color: #e15240;
                }
                &.no-homology {
                  color: #4587ff;
                }
              }
            }
          }
          .authorize {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
            &.is-blue {
              color: #4587ff;
            }
            &.is-gray {
              color: #999999;
            }
          }
          .operate {
            @include flex-between;
            > span {
              flex-shrink: 0;
              width: 16px;
              height: 16px;
              cursor: pointer;
              margin-right: 20px;
            }
            .zip {
              @include backgroundGroup("~assets/images/proof.png");
            }
            .zip:hover {
              @include backgroundGroup("~assets/images/proof-blue.png");
            }
            .report {
              position: relative;
              @include backgroundGroup("~assets/images/store-his-report.png");
              .submenu {
                @include noselect;
                &.submenu-active {
                  @include flex-center(column);
                  justify-content: normal;
                  align-items: normal;
                }
                display: none;
                position: absolute;
                z-index: 1000;
                top: 20px;
                left: 5px;
                width: 99px;
                height: 102px;
                padding: 15px 0;
                background: #ffffff;
                box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
                border-radius: 5px;
                box-sizing: border-box;
                span {
                  padding:0 15px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #999999;
                  line-height: 1;
                  cursor: pointer;
                  &:hover {
                    color: #333333;
                  }
                  &:first-child ~ span {
                    margin-top: 20px;
                  }
                }
              }
            }
            .report:hover {
              @include backgroundGroup(
                "~assets/images/store-his-report-blue.png"
              );
            }
            .delete {
              @include backgroundGroup("~assets/images/delete.png");
            }
            .delete:hover {
              @include backgroundGroup("~assets/images/delete-blue.png");
            }
          }
        }
      }
      ::v-deep .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
    }
  }
  &-footer {
    @include noselect;
    margin: 21px auto;
    ::v-deep .el-pagination {
      button {
        width: 80px;
      }
    }
  }
}
</style>
